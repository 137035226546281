<template>
  <div class="output-carousel-wrapper">
    <div ref="outputcarousel" class="output-carousel">
      <div
        v-for="(slide, idx) in loading ? [] : slides"
        :key="`output-carousel-slide-${idx}`"
        class="output-carousel-slide-wrapper"
      >
        <div
          :id="`output-carousel-slide-${output.uuid}-${idx}`"
          class="output-carousel-slide"
          :class="{
            selected: selectedIdx === idx && selectedBlockIdx === -1
          }"
          @click="() => $emit('select', idx)"
        >
          <img
            v-if="slideRegenerating !== idx"
            :src="slide.preview"
            alt=""
            class="output-carousel-slide-preview"
          />
          <b-skeleton v-else class="output-carousel-slide-preview" />
          <div class="output-carousel-slide-dynamic-wrapper">
            <b-tooltip
              v-if="slide.dynamic"
              label="AI enhanced slide"
              type="is-dark"
              position="is-top"
              :append-to-body="true"
            >
              <div class="output-carousel-slide-dynamic">
                <img
                  src="@/assets/icons/ai.svg"
                  alt=""
                  class="output-carousel-slide-dynamic-icon"
                />
              </div>
            </b-tooltip>
          </div>
        </div>
        <div
          v-if="slideBlockPreviews[idx].length"
          class="output-carousel-slide-block"
        >
          <div
            v-for="(insertedSlide, blockIdx) in slideBlockPreviews[idx]"
            :id="`output-carousel-slide-${output.uuid}-${idx}-${blockIdx}`"
            :key="`output-carousel-slide-block-${blockIdx}`"
            class="output-carousel-slide inserted"
            :class="{
              selected: selectedIdx === idx && selectedBlockIdx === blockIdx
            }"
            @click="() => $emit('select', idx, blockIdx)"
          >
            <img
              :src="insertedSlide.preview"
              alt=""
              class="output-carousel-slide-preview"
            />
            <div class="output-carousel-slide-dynamic-wrapper">
              <b-tooltip
                :label="`Inserted from ${insertedSlide.file.name}`"
                type="is-dark"
                position="is-top"
                :append-to-body="true"
              >
                <div class="output-carousel-slide-dynamic inserted">
                  <img
                    src="@/assets/icons/compass.svg"
                    alt=""
                    class="output-carousel-slide-dynamic-icon"
                  />
                </div>
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
      <b-skeleton
        v-for="s in loading || !(slides && slides.length)
          ? [1, 2, 3, 4, 5, 6, 7]
          : []"
        :key="`output-carousel-slide-${s}`"
        class="output-carousel-slide-preview"
      />
    </div>
    <img
      v-if="!leftArrowDisabled"
      src="@/assets/icons/carousel-arrow-right.svg"
      alt=""
      class="output-carousel-arrow left"
      @click="() => gotoSlide(-1)"
    />
    <img
      v-if="!rightArrowDisabled"
      src="@/assets/icons/carousel-arrow-right.svg"
      alt=""
      class="output-carousel-arrow right"
      @click="() => gotoSlide(1)"
    />
  </div>
</template>

<script>
export default {
  name: 'PresentationOutputCarousel',
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    blocks: {
      type: Array,
      default: () => []
    },
    output: {
      type: Object,
      default: () => ({})
    },
    selectedIdx: {
      type: Number,
      default: -1
    },
    selectedBlockIdx: {
      type: Number,
      default: -1
    },
    loading: {
      type: Boolean,
      default: false
    },
    slideRegenerating: {
      type: Number,
      default: -1
    },
    slideBlockPreviews: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    leftArrowDisabled() {
      return this.selectedIdx === 0 && this.selectedBlockIdx === -1
    },
    rightArrowDisabled() {
      return (
        this.selectedIdx === this.slides.length - 1 &&
        (this.slideBlockPreviews[this.selectedIdx]?.length
          ? this.selectedBlockIdx ===
            this.slideBlockPreviews[this.selectedIdx].length - 1
          : true)
      )
    }
  },
  methods: {
    gotoSlide(direction) {
      this.$emit(direction > 0 ? 'next' : 'previous')
    },
    scrollToIndex(idx, blockIdx = -1) {
      const el = this.$refs.outputcarousel
      const slide = document.getElementById(
        `output-carousel-slide-${this.output.uuid}-${idx}${blockIdx > -1 ? `-${blockIdx}` : ''}`
      )
      if (!el || !slide) return
      el.scrollTo({
        left: slide.offsetLeft - el.offsetWidth / 2 + slide.offsetWidth / 2,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.output-carousel {
  display: flex;
  flex-flow: column wrap;
  gap: 1.5rem;
  overflow-x: auto;
  height: 100%;
  max-height: 100%;
  width: fit-content;
  margin: 0 auto;
  padding: 0 1.5rem;

  --output-carousel-border-radius: 8px;
  --output-carousel-inner-padding: 0.25rem;

  &::-webkit-scrollbar {
    display: none;
  }

  &-wrapper {
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  &-slide {
    position: relative;
    height: 100%;
    width: fit-content;
    cursor: pointer;
    border-radius: var(--output-carousel-border-radius);
    padding: var(--output-carousel-inner-padding);
    border: 2px solid transparent;
    transition: border-color 0.2s;

    &:hover {
      border-color: rgba($primary, 0.3);
    }

    &.selected {
      border-color: $primary;
    }

    &.inserted {
      &:hover {
        border-color: rgba(#8937df, 0.3);
      }

      &.selected {
        border-color: #8937df;
      }
    }

    &-wrapper,
    &-block {
      display: contents;
    }

    &-preview {
      height: 100%;
      border-radius: calc(
        var(--output-carousel-border-radius) -
          var(--output-carousel-inner-padding)
      );
    }

    &-dynamic {
      background: $primary;
      border-radius: 0
        calc(
          var(--output-carousel-border-radius) -
            var(--output-carousel-inner-padding)
        )
        0 12px;
      padding: 0.35rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.inserted {
        background: #8937df;
      }

      &-wrapper {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
      }

      &-icon {
        width: 1.2rem;
        height: 1.2rem;
        filter: brightness(0) invert(1);
      }
    }
  }

  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.2s, transform 0.2s;
    border-radius: 999rem;
    cursor: pointer;

    &.left {
      left: 0.5rem;
      transform: rotate(180deg) translateY(50%);

      &:hover {
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
      }
    }

    &.right {
      right: 0.5rem;

      &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
}

::v-deep .b-skeleton {
  margin: 0;
  border-radius: 8px;
  height: 100%;
  flex: unset !important;
  width: fit-content;

  &-item {
    height: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 8px;
  }
}
</style>
