var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "output-carousel-wrapper" }, [
    _c(
      "div",
      { ref: "outputcarousel", staticClass: "output-carousel" },
      [
        _vm._l(_vm.loading ? [] : _vm.slides, function(slide, idx) {
          return _c(
            "div",
            {
              key: "output-carousel-slide-" + idx,
              staticClass: "output-carousel-slide-wrapper"
            },
            [
              _c(
                "div",
                {
                  staticClass: "output-carousel-slide",
                  class: {
                    selected:
                      _vm.selectedIdx === idx && _vm.selectedBlockIdx === -1
                  },
                  attrs: {
                    id: "output-carousel-slide-" + _vm.output.uuid + "-" + idx
                  },
                  on: {
                    click: function() {
                      return _vm.$emit("select", idx)
                    }
                  }
                },
                [
                  _vm.slideRegenerating !== idx
                    ? _c("img", {
                        staticClass: "output-carousel-slide-preview",
                        attrs: { src: slide.preview, alt: "" }
                      })
                    : _c("b-skeleton", {
                        staticClass: "output-carousel-slide-preview"
                      }),
                  _c(
                    "div",
                    { staticClass: "output-carousel-slide-dynamic-wrapper" },
                    [
                      slide.dynamic
                        ? _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: "AI enhanced slide",
                                type: "is-dark",
                                position: "is-top",
                                "append-to-body": true
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "output-carousel-slide-dynamic"
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "output-carousel-slide-dynamic-icon",
                                    attrs: {
                                      src: require("@/assets/icons/ai.svg"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.slideBlockPreviews[idx].length
                ? _c(
                    "div",
                    { staticClass: "output-carousel-slide-block" },
                    _vm._l(_vm.slideBlockPreviews[idx], function(
                      insertedSlide,
                      blockIdx
                    ) {
                      return _c(
                        "div",
                        {
                          key: "output-carousel-slide-block-" + blockIdx,
                          staticClass: "output-carousel-slide inserted",
                          class: {
                            selected:
                              _vm.selectedIdx === idx &&
                              _vm.selectedBlockIdx === blockIdx
                          },
                          attrs: {
                            id:
                              "output-carousel-slide-" +
                              _vm.output.uuid +
                              "-" +
                              idx +
                              "-" +
                              blockIdx
                          },
                          on: {
                            click: function() {
                              return _vm.$emit("select", idx, blockIdx)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "output-carousel-slide-preview",
                            attrs: { src: insertedSlide.preview, alt: "" }
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "output-carousel-slide-dynamic-wrapper"
                            },
                            [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label:
                                      "Inserted from " +
                                      insertedSlide.file.name,
                                    type: "is-dark",
                                    position: "is-top",
                                    "append-to-body": true
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "output-carousel-slide-dynamic inserted"
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "output-carousel-slide-dynamic-icon",
                                        attrs: {
                                          src: require("@/assets/icons/compass.svg"),
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          )
        }),
        _vm._l(
          _vm.loading || !(_vm.slides && _vm.slides.length)
            ? [1, 2, 3, 4, 5, 6, 7]
            : [],
          function(s) {
            return _c("b-skeleton", {
              key: "output-carousel-slide-" + s,
              staticClass: "output-carousel-slide-preview"
            })
          }
        )
      ],
      2
    ),
    !_vm.leftArrowDisabled
      ? _c("img", {
          staticClass: "output-carousel-arrow left",
          attrs: {
            src: require("@/assets/icons/carousel-arrow-right.svg"),
            alt: ""
          },
          on: {
            click: function() {
              return _vm.gotoSlide(-1)
            }
          }
        })
      : _vm._e(),
    !_vm.rightArrowDisabled
      ? _c("img", {
          staticClass: "output-carousel-arrow right",
          attrs: {
            src: require("@/assets/icons/carousel-arrow-right.svg"),
            alt: ""
          },
          on: {
            click: function() {
              return _vm.gotoSlide(1)
            }
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }